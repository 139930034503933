@import '_tokens';
@import '_mixins';
@import '_fonts';
/*
 * General
 */

body {-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}


.container-fluid {max-width: 1200px;}

/*
 * Links
 */


/*
 * Buttons
 */
.btn {border-radius:0; white-space: nowrap;}

/* Button Sizing */
.btn-sm {padding:12px 15px; letter-spacing: 1px; cursor: pointer; text-transform: uppercase;}


/*
 * Stats
 */
 .stat-label {letter-spacing: 1px; text-transform:uppercase; color:#6A6B6B;}

/*
 * Step Footer
 */
.step {position: relative; min-height: 100vh; padding-bottom: 50px;}
.step .section:first-child .section-body {margin-top:136px;}
.step .section {padding-top:96px; padding-bottom: 96px;}
.step .section:first-child {padding-top:0;}
.steps-footer {position: fixed; bottom:0; width:100%; box-sizing: border-box; left:0; padding:12px; border-top:1px solid #eee; box-shadow: 0 0 12px 0 rgba(0,0,0,0.12); background:#fff; z-index: 10;}
.steps-footer .btn {width:165px;}

.step .section-bg-secondary {background:#F7F7F8;}

.step .offer-meta {margin-bottom:48px;}
.step .offer-description {margin-bottom:48px;}

.step-1 h1 {margin-bottom:24px;}
.step-1 .address {line-height: 1.3;}

.step .stat-icon + .stat-label {margin-top:12px; margin-bottom: 4px;}
.step .stat-number {margin-bottom: 24px;}
.step .stat-number + p {margin-bottom:0;}

/*
 * Map Markers
 */
 .map-marker-wrap {background:#333131; position: relative; opacity: .85;}
 .map-marker-wrap:after {content:''; border-top:5px solid #333131; border-left: 5px solid transparent; border-right: 5px solid transparent; position: absolute; left:50%; transform: translateX(-50%); top:100%;}
   .map-marker-wrap.marker-red {background:#777;}
   .map-marker-wrap.marker-red:after {border-top-color: #777;}

 .map-marker-wrap .icon-label {width:100%; position: absolute; left:0; top:50%; transform: translateY(-50%); text-align: center; line-height: inherit;}

/*
 * Border Between Utility Class
 */
 @media (min-width: 768px) {
  .row.border-between > [class^="col-md"] {border-left:1px solid #8C8C8C; margin-left:-1px; border-right:1px solid #8C8C8C; }
  .step .section-bg-secondary .row.border-between > [class^="col-md"]:first-child {border-left:0;}
  .step .section-bg-secondary .row.border-between > [class^="col-md"]:last-child {border-right:0;}
}


/* Mobile */
@media (max-width: 575px) {
  .brand {width:35px;}


  .step .section:first-child .section-body {margin-top:78px;}
  .step .section {padding-top:48px; padding-bottom: 48px;}
  .step .section .section-body {padding-left:32px; padding-right:32px;}

  /* Nav Controls */
  .cta {position: fixed; bottom:0; left: 0; bottom:0; width:100%;}
  .cta .btn {width:100%;}

  .steps-footer {padding:0; box-shadow: 0 0 8px rgba(0,0,0,.2)}
  .steps-footer .container-fluid {padding:0;}
    .steps-footer .btn {width:50%;}
    .steps-footer .btn-outline-primary,
    .steps-footer .btn-outline-primary:hover,
    .steps-footer .btn-outline-primary:active {border-color:transparent;}
}


.fadeIn {animation:.2s fadeIn;}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.popover {
  padding: 1.25rem;
  margin-bottom: 3px;
  font-family: 'CheltenhamPro';
  font-weight: 500;
  color: #696969;
  border-radius: 0;
  border: none;
  box-shadow: 0px 0px 15px 3px rgba(0,0,0,.05);

  width: 340px;
  max-width: 95vw;


  &.bs-popover-auto {
    &.top {
      transform: translateY(-10px);
    }
    &.bottom {
      transform: translateY(-0px);
    }
    &.right {
      transform: translateX(10px);
    }
    &.left {
      transform: translateX(-10px);
    }
  }


  &.bs-popover-top {
    .arrow::after, .arrow::before {
      border-top-color: #fff;
      margin-left: -.4rem;
    }
  }

  &.bs-popover-left {
    .arrow::after, .arrow::before {
      border-left-color: #fff;
    }
  }

  &.bs-popover-right {
    .arrow::after, .arrow::before {
      border-right-color: #fff;
    }
  }

  &.bs-popover-bottom {
    .arrow::after, .arrow::before {
      border-bottom-color: #fff;
      margin-left: -.4rem;
    }
  }
}

.popover-body {
  padding: 0;
  color: #31324C;
  line-height: normal;
}

.header-amount {
  line-height: 100px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-content: center;

  sup {
    vertical-align: middle;
  }
}

.box {
  background: #FFFFFF;
  border-radius: 25px;
  padding: 50px 25px;
}
@media (max-width: 767px) {
  .box {
    padding: 25px 12.5px;
    margin: 0 15px;
  }
}

.cursor-pointer {
  cursor: pointer;
}
