$layout-grid-breakpoints: (
  small-phone: 0,
  phone: 321px,
  tablet: 740px,
  laptop: 1020px,
  desktop: 1240px,
  large-desktop: 1440px
) !default;

// Layout mixins
@function breakpoint-next($name, $breakpoints: $layout-grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $layout-grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $layout-grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}

// Makes the @content apply to the given breakpoint and wider.
@mixin mq-up($name, $breakpoints: $layout-grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Makes the @content apply to the given breakpoint and narrower.
@mixin mq-down($name, $breakpoints: $layout-grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin mq-only($name, $breakpoints: $layout-grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include mq-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include mq-down($name, $breakpoints) {
      @content;
    }
  }
}

// Font mixins
@mixin text-sans-serif($font-size: false, $line-height: false, $letter-spacing: false, $font-weight: false) {
  font-family: $font-family-sans-serif;
  @if $font-size { font-size: $font-size; }
  @if $line-height { line-height: $line-height; }
  @if $letter-spacing { letter-spacing: $letter-spacing; }
  @if $font-weight { font-weight: $font-weight; }
}

@mixin text-serif($font-size: false, $line-height: false, $letter-spacing: false, $font-weight: false) {
  font-family: $font-family-serif;
  @if $font-size { font-size: $font-size; }
  @if $line-height { line-height: $line-height; }
  @if $letter-spacing { letter-spacing: $letter-spacing; }
  @if $font-weight { font-weight: $font-weight; }
}
