h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

body {
  @include text-sans-serif(16px, 24px, 0.25px, 500);
}

h1, .text-h1 {
  @include text-serif(70px, 76px, -1px, 300);

  @include mq-down(tablet) {
    @include text-serif(50px, 60px, -2px);
  }
}

h2, .text-h2 {
  @include text-serif(50px, 58px, -0.1px, 300);

  @include mq-down(tablet) {
    @include text-serif(40px, 48px, 0);
  }
}

h3, .text-h3 {
  @include text-serif(40px, 48px, 0, 300);

  @include mq-down(tablet) {
    @include text-serif(30px, 36px, 0, 300);
  }
}

h4, .text-h4 {
  @include text-sans-serif(30px, 36px, 0, 700);

  @include mq-down(tablet) {
    @include text-sans-serif(25px, 32px, 0);
  }
}

.text-body-lg {
  @include text-sans-serif(20px, 32px, 0, 500);
}

.text-body-md, .text-base, .text-body {
  @include text-sans-serif(16px, 24px, 0.25px, 500);
}

.text-body-sm {
  @include text-sans-serif(14px, 24px, 0.25px, 500);
}

.text-body-xs {
  @include text-sans-serif(12px, 20px, 0, 500);
}

.text-label {
  @include text-sans-serif(13px, 20px, 2px, 700);
  text-transform: uppercase;
}

.text-caption {
  @include text-sans-serif(10px, 16px, 0, 500);
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}
