body {background-color:white; color:#31324C; font-family: 'CheltenhamPro', sans-serif; font-size:16px; line-height: 24px;}

/* Links */
a {color:#E87A59}
a:hover {color:#c95233;}

.brand {position: absolute; top:0; left:0; width: 100%; text-align: center; margin-top:25px;}
.brand img {width:113px; }

/* Btn Colors */
/* Btn Colors */
.btn {font-family: 'SharpGrotesk'; }

.btn-primary {
  font-family: 'SharpGrotesk';
  background-color:#E87A59;
  color:#fff!important;
  border-color:#E87A59;
  font-size: 14px;
  padding: 15px 60px;
  text-transform: initial;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 700;
}
  .btn-primary:hover,
  .btn-primary:focus {background-color:#d05c3a; border-color:#d05c3a;}
  .btn-primary:not([disabled]):not(.disabled).active, .btn-primary:not([disabled]):not(.disabled):active, .show>.btn-primary.dropdown-toggle {background-color:#b54321; border-color:#b54321;}

.btn-outline-primary {background-color:#fff; color:#E87A59!important; border-color: #E87A59;}
  .btn-outline-primary:hover,
  .btn-outline-primary:focus {background-color:#fff; color:#d05c3a!important; border-color: #d05c3a;}
  .btn-outline-primary:not([disabled]):not(.disabled).active, .btn-outline-primary:not([disabled]):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {background-color:#fff; color:#b54321!important; border-color:#b54321;}

.step .sub-heading.label {color:#75968A; font-family: 'SharpGrotesk-Medium20'; font-weight: normal; text-transform: uppercase; letter-spacing: 1px;}
.step .sub-heading.sentence {font-family: 'CheltenhamPro'; font-weight: normal; font-size:28px;}

.step .stat-label,
.step .comp .photo {color:#7D7F7E; font-family: 'SharpGrotesk-Medium20'; font-weight: normal;}
.step .comp {box-shadow: 0 0 12px 0 rgba(0,0,0,0.2)}

.step .section-bg-secondary {background:#31324C; color:#fff;}
.step .section-bg-secondary h2 {color:#A7E9F1;}
.step .section-bg-secondary .stat-label {color:#fff;}
.step .section-bg-secondary .sub-heading.label {color:#A7E9F1;}

.step .section-bg-secondary .row.border-between > div {border-color:#8C8C8C; }


#offerTab .section-bg-secondary {background-color: #E0DCD3; color:#31324C;}

.step .stat-number {color:#31324C; font-family: 'CheltenhamPro', serif; font-weight: normal;}

.step-1 .address {font-family: 'CheltenhamPro', serif; font-weight: 300;}

@media(min-width: 576px){
  .step .stat-number {font-size:100px; line-height: 112px;}
  .step-1 .address {font-size:28px;}
}

.pricing-table .brand-col {background-color: #EFD3C2!important;}


.map-marker-wrap {background-color: #75968A; font-family: 'SharpGrotesk-Medium20';}
  .map-marker-wrap:after {border-top-color: #75968A;}
.map-marker-wrap.marker-red {background-color: #E87A59;}
  .map-marker-wrap.marker-red:after {border-top-color: #E87A59;}

.comp {font-family: 'SharpGrotesk-Book20';}
.comp .street-address {font-size:20px!important; font-family: 'SharpGrotesk-Medium20'; font-weight: normal!important;}
.comp .index {background-color: #75968A!important; font-family: 'SharpGrotesk-Medium20';}

@media (min-width: 576px){
  .pricing-table > .row.section-head > div:before {border-color:#8c8c8c!important;}
}

.small {font-size:13px;}




/* Pricing Table */
.pricing-table .row > [class^="col"]:first-child {font-family: "CheltenhamPro";}
.pricing-table .row > [class^="col"]:not(:first-child) {font-family: 'SharpGrotesk-Book20'; }
.pricing-table .row.top .title {font-family: "CheltenhamPro"; font-size:22px;}
.pricing-table .row > [class^="col"] .col-label {font-family: "CheltenhamPro"; display: block;}

@media (max-width: 767px) {
  .pricing-table .row:not(.top) > .row-label {font-family: 'SharpGrotesk-Medium20'; color:#75968A;}
  .pricing-table .row:not(.top) .comp-col {background:#f9f9f9; }
}
